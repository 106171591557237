import React from "react";

import Layout from "../components/global/layout";
import SEO from "../components/global/seo";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
  </Layout>
);

export default NotFoundPage;
